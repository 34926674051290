import React from 'react'
import Layout from '../../../../components/DE/LayoutDE'
import ServiceHeader from '../../../../components/Services/ServiceOverview/ServiceHeader'
import '../../../../components/ParagraphUnderPhoto/styles.css'
import './styles.css'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Image from '../../../../components/Image/Image'

export const Dane = ({
  data: { page, image1, image2, image3, yellowArrow },
}) => {
  const { frontmatter } = page

  return (
    <>
      <Layout
        meta={page.frontmatter.meta || false}
        title={page.frontmatter.title || false}
      >
        <ServiceHeader
          title={frontmatter.title}
          backgroundImage={
            !!frontmatter.image.childImageSharp
              ? frontmatter.image.childImageSharp.fluid.src
              : frontmatter.image
          }
        />
        <div className="container">
          <div className="PageWrapper row justify-content-md-center align-items-center">
            <div className="col-md-6">
              {!!image1 && !!image1.childImageSharp ? (
                <Img
                  fluid={image1.childImageSharp.fluid}
                  alt="pripadove-studie-1"
                />
              ) : (
                <img src={image1.src} alt="pripadove-studie-1" />
              )}
            </div>
            <div className="col-md-6">
              <div className="BlueBox">
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Umsatzsteuerregistrierung von mehreren hundert
                    Steuersubjekten
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Einführung eines Systems von sogenannten
                    Call-Off-Stock-Lagern für mehrere Dutzend Lieferanten einer
                    großen Einzelhandelskette
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Steuerliche Lösungen bei der Umstrukturierung einer großen
                    Sportanlage
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Gestaltung vieler Transformationsprozesse und Unterstützung
                    bei deren Implementierung
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Studie über Verrechnungspreise für eine multinationale
                    Gruppe und deren Verteidigung vor der Steuerverwaltung
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="PageWrapper row justify-content-md-center align-items-center">
            <div className="col-md-6">
              <div className="BlueBox">
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>Verkauf einer bedeutenden privaten Bildungseinrichtung</p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Erfolgreiche Vertretung bei einer Berufung gegen die
                    Ergebnisse der Kontrolle des sogenannten Švarc-Systems
                    (Scheinselbständigkeit) bei einem Produktionsunternehmen
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Einführung der Online-Buchhaltung für ein Handelsunternehmen
                    sowie eine Produktionsfirma mit mehreren Dutzend
                    Mitarbeitern
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Vertretung in einem Streit mit der Finanzverwaltung in der
                    Tschechischen Republik über die Umsatzsteuer in Höhe von
                    über 30 Mio. CZK
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {!!image2 && !!image2.childImageSharp ? (
                <Img
                  fluid={image2.childImageSharp.fluid}
                  alt="pripadove-studie-2"
                />
              ) : (
                <img src={image2.src} alt="pripadove-studie-2" />
              )}
            </div>
          </div>
          <div className="PageWrapper row justify-content-md-center align-items-center">
            <div className="col-md-6">
              {!!image3 && !!image3.childImageSharp ? (
                <Img
                  fluid={image3.childImageSharp.fluid}
                  alt="pripadove-studie-3"
                />
              ) : (
                <img src={image3.src} alt="pripadove-studie-3" />
              )}
            </div>
            <div className="col-md-6">
              <div className="BlueBox">
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Umstrukturierung einer Produktionsanlage, Aufspaltung in
                    mehrere Teile und Unterstützung beim Verkauf der wichtigsten
                    Vermögenswerte an einen neuen Eigentümer
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Unterstützung beim Verkauf eines Kleinwasserkraftwerks in
                    der Tschechischen Republik
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>Due Diligence beim Kauf und Verkauf vieler Unternehmen</p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Unterstützung bei der Implementierung des ERP-Systems in
                    einem produzierenden Unternehmen mit einem Umsatz in
                    Milliarden-CZK-Einheiten
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Nationale und internationale Strukturierung von
                    Transaktionen - Entwurf und Implementierung von
                    Akquisitionsstrukturen, Liquidation von Offshore-Strukturen
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default Dane

export const pageQuery = graphql`
  query PripadoveStudieDE {
    page: markdownRemark(
      frontmatter: { slug: { eq: "de/uber-uns/fallstudie" } }
    ) {
      ...Meta
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    image1: file(relativePath: { eq: "pripadove-studie1.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "pripadove-studie2.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "pripadove-studie3.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    yellowArrow: file(relativePath: { eq: "yellow-arrow.png" }) {
      childImageSharp {
        fluid {
          src
        }
      }
    }
  }
`
